import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { U21Card, U21Table, U21TitleCountLabel } from '@u21/components';

import { RequestNewApiKeyButton } from 'features/settings/tabs/apiKeys/components/RequestNewApiKeyButton';

import { API_KEYS_COLUMNS } from 'features/settings/tabs/apiKeys/columns';
import { getOrgApiKeys } from 'features/settings/sliceSettings';
import {
  selectLoadingApiKeys,
  selectApiKeys,
} from 'features/settings/selectors';
import { selectPermissions } from 'features/agents/selectors';
import { hasPermissionsRequired } from 'features/session/utils';
import { permissions } from 'app/shared/utils/permissions';

export const ApiKeys = () => {
  const dispatch = useAppDispatch();
  const loadingApiKeys = useAppSelector(selectLoadingApiKeys);
  const apiKeys = useAppSelector(selectApiKeys);

  useEffect(() => {
    dispatch(getOrgApiKeys());
  }, [dispatch]);

  const agentPermissions = useAppSelector(selectPermissions);

  const hasCreatePermissions = hasPermissionsRequired(agentPermissions, [
    permissions.createApiKeys,
  ]);

  return (
    <U21Card
      title={
        <U21TitleCountLabel count={apiKeys.length} label="API Key">
          API Keys
        </U21TitleCountLabel>
      }
      action={
        hasCreatePermissions && (
          <RequestNewApiKeyButton disabled={apiKeys.length >= 10}>
            Request new API key
          </RequestNewApiKeyButton>
        )
      }
    >
      <U21Table
        columns={API_KEYS_COLUMNS}
        count={apiKeys.length}
        data={apiKeys}
        loading={loadingApiKeys}
        label="api-keys"
        defaultSortBy={[{ desc: true, id: 'createdAt' }]}
      />
    </U21Card>
  );
};
