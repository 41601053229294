import { post } from 'app/shared/utils/fetchr';
import {
  ListRecordPayload,
  RetrieveEntitiesPayload,
} from 'features/searchRecords/sliceRecords';

export const retrieveEntities = (
  payload: RetrieveEntitiesPayload,
): Promise<ListRecordPayload> => {
  return post('/entities/query', { ids: payload.searchQuery });
};
