import { EmptyPayload, post } from 'app/shared/utils/fetchr';
import { Agent } from 'features/agents/models';

export const sendAuthEmail = async (email: string): Promise<EmptyPayload> => {
  return post('/send-auth-email', { email });
};

export interface ExchangeAuthEmailTokenPayload {
  jwt: string;
  jwt_expires_at: number;
  refresh_token_expires: number;
}
export const exchangeAuthEmailToken = async (
  token: string,
): Promise<ExchangeAuthEmailTokenPayload> => {
  return post('/exchange-auth-email-token', { token });
};

export const logout = async (): Promise<EmptyPayload> => {
  return post('/logout');
};

export const login = async (): Promise<Agent> => {
  return post('/login');
};
