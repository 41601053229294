import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { U21Card, U21Table, U21TitleCountLabel } from '@u21/components';

import { AddNewAgentButton } from 'features/settings/tabs/agents/components/AddNewAgentButton';

import { API_KEYS_COLUMNS } from 'features/settings/tabs/agents/columns';
import { getAllAgents } from 'features/settings/sliceSettings';
import { selectAgents, selectLoadingAgents } from 'features/settings/selectors';
import { hasPermissionsRequired } from 'features/session/utils';
import { permissions } from 'app/shared/utils/permissions';
import { selectPermissions } from 'features/agents/selectors';

export const Agents = () => {
  const dispatch = useAppDispatch();
  const agents = useAppSelector(selectAgents);
  const loadingAgents = useAppSelector(selectLoadingAgents);
  const agentPermissions = useAppSelector(selectPermissions);

  const hasCreatePermissions = hasPermissionsRequired(agentPermissions, [
    permissions.createAgents,
  ]);

  useEffect(() => {
    dispatch(getAllAgents());
  }, [dispatch]);

  return (
    <U21Card
      title={
        <U21TitleCountLabel count={agents.length} label="Agent">
          Agents
        </U21TitleCountLabel>
      }
      action={
        hasCreatePermissions && (
          <AddNewAgentButton disabled={agents.length >= 10}>
            Add new agent
          </AddNewAgentButton>
        )
      }
    >
      <U21Table
        columns={API_KEYS_COLUMNS}
        count={agents.length}
        data={agents}
        loading={loadingAgents}
        label="agents"
        defaultSortBy={[{ desc: true, id: 'lastSeen' }]}
      />
    </U21Card>
  );
};
