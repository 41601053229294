// Redux / Thunk Toolkits
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';

import { RouteComponentProps } from 'react-router-dom';

import {
  sendAuthEmail,
  ExchangeAuthEmailTokenPayload,
  exchangeAuthEmailToken,
  login,
} from 'features/session/api';

import { AuthClient } from 'app/shared/auth/models';
import { updateAgent } from 'features/agents/sliceAgents';

const SESSION_NAME = 'sessionSlice';
interface SessionState {
  loadingSendAuthEmail: boolean;
  authEmailSent: boolean;
  accessToken: null | string;
  loadingEmailTokenExchange: boolean;
  loadingLogout: boolean;
  lastUrl?: string;
  loadingApp: boolean;
}

export const initialState: SessionState = {
  loadingSendAuthEmail: false,
  authEmailSent: false,
  accessToken: null,
  loadingEmailTokenExchange: false,
  loadingLogout: false,
  loadingApp: true,
};

export const sendAuthEmailThunk = u21CreateAsyncThunk(
  `${SESSION_NAME}/SEND_AUTH_EMAIL`,
  async (payload: string) => {
    await sendAuthEmail(payload);
  },
);

type EmailTokenExchangeAction = {
  token: string;
  auth: AuthClient;
  history: RouteComponentProps['history'];
};
export const emailTokenExchangeThunk = u21CreateAsyncThunk(
  `${SESSION_NAME}/EMAIL_TOKEN_EXCHANGE`,
  async ({ token, auth, history }: EmailTokenExchangeAction, { dispatch }) => {
    try {
      const data: ExchangeAuthEmailTokenPayload = await exchangeAuthEmailToken(
        token,
      );
      await auth.login(data);
      await dispatch(initSession(auth));
      history.push('/');

      return data;
    } catch (e) {
      window.location.replace('/login');
      throw e;
    }
  },
);

export const logoutThunk = u21CreateAsyncThunk(
  `${SESSION_NAME}/LOGOUT`,
  async (auth: AuthClient) => {
    await auth.logout();
  },
);

export const initSession = u21CreateAsyncThunk(
  `${SESSION_NAME}/INIT_SESSION`,
  async (auth: AuthClient, { dispatch }) => {
    if (!auth.isAuthenticated()) {
      const url = window.location.pathname;
      if (url && !/access_token|error/.test(url)) {
        setLastRoute(url);
      }

      await auth.renewSession();
    }
    const data = await login();

    dispatch(updateAgent(data));
  },
);

const sessionSlice = u21CreateSlice({
  name: SESSION_NAME,
  initialState,
  reducers: {
    updateAuthEmailSent: (draft, action) => {
      draft.authEmailSent = action.payload;
    },
    setAccessToken: (draft, action) => {
      draft.accessToken = action.payload;
    },
    setLastRoute: (draft, action) => {
      draft.lastUrl = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addLoadingCase(sendAuthEmailThunk, 'loadingSendAuthEmail', (draft) => {
        draft.authEmailSent = true;
      })
      .addLoadingCase(logoutThunk, 'loadingLogout', () => {
        window.location.replace('/login');
      })
      .addLoadingCase(initSession, 'loadingApp')
      .addLoadingCase(emailTokenExchangeThunk, 'loadingEmailTokenExchange');
  },
});

export const sessionSliceName = sessionSlice.name;
export const { updateAuthEmailSent, setAccessToken, setLastRoute } =
  sessionSlice.actions;
export default sessionSlice.reducer;
