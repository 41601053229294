import { RootState } from 'app/store';

export const selectAuthEmailSent = (state: RootState) =>
  state.session.authEmailSent;

export const selectSendAuthEmailLoading = (state: RootState) =>
  state.session.loadingSendAuthEmail;

export const selectEmailTokenExchangeLoading = (state: RootState) =>
  state.session.loadingEmailTokenExchange;

export const selectAccessToken = (state: RootState) =>
  state.session.accessToken;

export const selectAppLoading = (state: RootState) => state.session.loadingApp;
