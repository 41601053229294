import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from 'providers/AuthProvider';

// React-Redux
import { useAppDispatch, useAppSelector } from 'app/hooks';

// Components
import { IconLogout, IconSettings } from '@u21/tabler-icons';
import { U21Avatar, U21Typography, U21Spacer } from '@u21/components';
import { MenuItem, Box } from '@mui/material';
import { MenuPopover } from 'app/shared/components/MenuPopover';

// Thunks
import { logoutThunk } from 'features/session/sliceSession';

// Selectors
import { selectAgent } from 'features/agents/selectors';

// Utils
import { getA11yClickProps } from 'app/shared/utils/a11y';
import styled from 'styled-components';

export const AccountPopover = () => {
  const dispatch = useAppDispatch();
  const agent = useAppSelector(selectAgent);
  const { authClient } = useAuth();

  const MENU_OPTIONS = [
    {
      label: 'Settings',
      icon: <IconSettings />,
      linkTo: '/settings',
    },
    {
      label: 'Logout',
      icon: <IconLogout />,
      linkTo: '',
      onClick: () => dispatch(logoutThunk(authClient)),
    },
  ];

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (option) => {
    option.onClick?.();
    setOpen(false);
  };

  return (
    <>
      <Container
        ref={anchorRef}
        {...getA11yClickProps(handleOpen)}
        horizontal
        spacing={2}
      >
        <U21Avatar name={agent.fullName} />
        <div>
          <Username variant="body2">{agent.fullName}</Username>
          <Org color="text.secondary" variant="body2">
            {agent.email}
          </Org>
        </div>
      </Container>
      <StyledMenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
      >
        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={() => handleClose(option)}
            sx={{ typography: 'body1', py: 1, px: 2.5 }}
          >
            <Box mr={2}>{option.icon}</Box>
            {option.label}
          </MenuItem>
        ))}
      </StyledMenuPopover>
    </>
  );
};

const StyledMenuPopover = styled(MenuPopover)`
  .MuiPaper-root {
    padding-top: 8px;
    width: 250px;
  }
`;
const Container = styled(U21Spacer)`
  display: flex;
  padding: 10px 15px 10px 15px;
  cursor: pointer;

  :hover {
    border-radius: 8px;
    background: ${(props) => props.theme.palette.grey[200]};
  }
`;

const Username = styled(U21Typography)`
  white-space: nowrap;
  .MuiTypography-root {
    font-weight: 700;
  }
`;

const Org = styled(U21Typography)`
  white-space: nowrap;
`;
