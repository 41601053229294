import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import sessionSliceReducer from 'features/session/sliceSession';
import agentSliceReducer from 'features/agents/sliceAgents';
import fileUploadsSliceReducer from 'features/fileUploads/sliceFileUploads';
import recordSliceReducer from 'features/searchRecords/sliceRecords';
import settingSliceReducer from 'features/settings/sliceSettings';

export const reducer = {
  session: sessionSliceReducer,
  agent: agentSliceReducer,
  fileUpload: fileUploadsSliceReducer,
  record: recordSliceReducer,
  settings: settingSliceReducer,
};

export const store = configureStore({
  reducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
