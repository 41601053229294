import { U21TableState } from '@u21/components';

export interface PaginationPayload {
  limit: number;
  offset: number;
  sort_direction?: number;
  sort_column?: string;
}

export const createPaginationPayload = (
  state: U21TableState,
): PaginationPayload => {
  const { page, pageSize, sortBy } = state;
  const { desc, id } = sortBy[0] ?? {};
  return {
    limit: pageSize,
    offset: page,
    sort_column: id,
    sort_direction: desc ? -1 : 1,
  };
};
