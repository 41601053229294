import { U21TableColumnTypes } from '@u21/components';

const FULL_NAME = {
  id: 'full_name',
  Header: 'Name',
  accessor: 'fullName',
  type: U21TableColumnTypes.TEXT,
};
const EMAIL = {
  id: 'email',
  Header: 'Email',
  accessor: 'email',
  type: U21TableColumnTypes.TEXT,
};
const STATUS = {
  id: 'status',
  Header: 'Status',
  accessor: 'status',
  type: U21TableColumnTypes.TEXT,
};
const LAST_SEEN = {
  id: 'last_seen',
  Header: 'Last seen',
  accessor: 'lastSeen',
  type: U21TableColumnTypes.DATETIME,
};

export const API_KEYS_COLUMNS = [FULL_NAME, EMAIL, STATUS, LAST_SEEN];
