import {
  Children,
  Fragment,
  isValidElement,
  ReactElement,
  ReactNode,
} from 'react';

import isPropValid from '@emotion/is-prop-valid';

export const getDOMProps = (props: { [key: string]: any }) => {
  return Object.keys(props)
    .filter((i) => isPropValid(i))
    .reduce(
      (acc, i) => ({
        ...acc,
        [i]: props[i],
      }),
      {},
    );
};

export const flattenChildren = (children: ReactNode): ReactNode[] => {
  return Children.toArray(children).reduce((acc: ReactNode[], i) => {
    if (isValidElement(i) && i?.type === Fragment) {
      return [...acc, ...flattenChildren(i.props.children)];
    }
    return [...acc, i];
  }, []) as ReactNode[];
};

export const isRenderable = (children: ReactElement | ReactNode): boolean => {
  if (typeof children === 'string') {
    return children !== '';
  } else if (typeof children === 'number') {
    return true;
  } else if (typeof children === 'boolean') {
    return false;
  } else if (Array.isArray(children)) {
    return children.some(isRenderable);
  }
  return isValidElement(children);
};
