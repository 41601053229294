import { permissions } from 'app/shared/utils/permissions';

export interface Route {
  exact?: boolean;
  id: string;
  name: string;
  path: string;
  permissions: string[];
}
export const ROUTES: Route[] = [
  { exact: true, id: 'home', path: '/', name: 'Home', permissions: [] },
  {
    exact: true,
    id: 'searchRecords',
    name: 'Search Records',
    path: '/search-records',
    permissions: [],
  },
  {
    exact: true,
    id: 'fileUploads',
    name: 'File Uploads',
    path: '/file-uploads',
    permissions: [],
  },
  {
    exact: true,
    id: 'apiKeys',
    name: 'API Keys',
    path: '/settings/api-keys',
    permissions: [permissions.readApiKeys],
  },
  {
    exact: true,
    id: 'agents',
    name: 'Agents',
    path: '/settings/agents',
    permissions: [permissions.readAgents],
  },
];

export const ROUTES_MAP: Record<Route['id'], Route> = ROUTES.reduce(
  (acc, i) => ({
    ...acc,
    [i.id]: i,
  }),
  {},
);
