import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';

// Components
import { Redirect } from 'react-router-dom';
import {
  U21_FORM_FIELD_TYPES,
  U21Card,
  U21Spacer,
  U21Form,
  U21Typography,
  U21Button,
  U21FormField,
} from '@u21/components';
import U21Icon from 'app/shared/components/U21Icon';
import assets from 'assets/assets';

// Styles
import styled, { css } from 'styled-components';

// Selectors
import {
  selectAuthEmailSent,
  selectSendAuthEmailLoading,
} from 'features/session/selectors';

// Thunks
import {
  sendAuthEmailThunk,
  updateAuthEmailSent,
} from 'features/session/sliceSession';

// Utils
import isEmailValid from 'app/shared/utils/isEmailValid';
import { getA11yClickProps } from 'app/shared/utils/a11y';
import { useAuth } from 'providers/AuthProvider';

export const Login = () => {
  const [email, setEmail] = useState<string>('');
  const dispatch = useAppDispatch();
  const authEmailSent = useAppSelector(selectAuthEmailSent);
  const sendAuthEmailLoading = useAppSelector(selectSendAuthEmailLoading);
  const { authClient } = useAuth();

  const reEnterEmail = () => {
    dispatch(updateAuthEmailSent(false));
  };

  document.title = 'Fintech Fraud DAO - Login';

  if (authClient.isAuthenticated()) {
    return <Redirect to="/" />;
  }

  return (
    <Container>
      <StyledU21Card>
        {authEmailSent ? (
          <StyledU21Spacer spacing="16px">
            <img alt="Email" src={assets.images.emailCloud} />
            <StyledU21Typography variant="h4">
              Check your email
            </StyledU21Typography>
            <StyledU21Typography variant="body2">
              If your email address <Bold>{email}</Bold> is registered with
              Fintech Fraud DAO, you&apos;ll receive a magic link to login!
            </StyledU21Typography>
            <StyledU21Typography variant="caption" color="text.secondary">
              Can&apos;t see the email? Please check your spam folder.
              <br />
              Wrong Email?{' '}
              <BackButton {...getA11yClickProps(reEnterEmail)}>
                Please re-enter your address.
              </BackButton>
            </StyledU21Typography>
          </StyledU21Spacer>
        ) : (
          <StyledU21Spacer spacing={4}>
            <StyledImg
              src={assets.images.daoLogo}
              alt="Fintech Fraud DAO Logo"
            />
            <StyledU21Form
              onSubmit={({ email: emailInput }) => {
                setEmail(emailInput);
                dispatch(sendAuthEmailThunk(emailInput));
              }}
              loading={sendAuthEmailLoading}
            >
              <U21FormField
                fieldProps={{
                  clearable: false,
                  label: 'Your work email',
                }}
                name="email"
                type={U21_FORM_FIELD_TYPES.TEXT}
                validate={(value) =>
                  isEmailValid(value) ? undefined : 'Please enter a valid email'
                }
              />
              <ButtonContainer>
                <U21Button
                  color="primary"
                  size="large"
                  startIcon={
                    <U21Icon assetIcon={assets.icons.mail} size={24} />
                  }
                  type="submit"
                  variant="contained"
                  loading={sendAuthEmailLoading}
                >
                  Send me a magic link
                </U21Button>
              </ButtonContainer>
            </StyledU21Form>
          </StyledU21Spacer>
        )}
      </StyledU21Card>
    </Container>
  );
};

const Container = styled('div')`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledU21Card = styled(U21Card)`
  max-width: 575px;
  width: 100%;
  border-radius: 16px;
`;

const StyledU21Spacer = styled(U21Spacer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 460px;
  margin: 0 auto;
  padding-top: 30px;
`;

const StyledImg = styled('img')`
  max-width: 350px;
  width: 100%;
`;

const StyledU21Form = styled(U21Form)`
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
`;

const StyledU21Typography = styled(U21Typography)`
  width: 100%;
  justify-content: center;
  text-align: center;
`;

const Bold = styled('span')`
  font-weight: 700;
`;

const BackButton = styled('span')`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    cursor: pointer;
  `}
`;
