import { U21TableColumnTypes } from '@u21/components';
import { humanReadableFileSize } from 'app/shared/utils/helpers';
import { UploadStatus } from 'features/fileUploads/components/UploadStatus';

const FILE_NAME = {
  id: 'file_name',
  Header: 'File Name',
  accessor: 'file_name',
  type: U21TableColumnTypes.TEXT,
};
const SIZE = {
  id: 'file_size',
  Header: 'Size',
  accessor: (row) => humanReadableFileSize(row.file_size),
  type: U21TableColumnTypes.TEXT,
};
const UPLOAD_DATE = {
  id: 'uploaded_at',
  Header: 'Upload Date',
  accessor: 'uploaded_at',
  type: U21TableColumnTypes.DATETIME,
};
const STATUS = {
  id: 'status',
  Header: 'Status',
  type: U21TableColumnTypes.LABEL,
  accessor: (row) => {
    return <UploadStatus row={row} />;
  },
};
export const FILE_EXPLORER_COLUMNS = [FILE_NAME, SIZE, UPLOAD_DATE, STATUS];
