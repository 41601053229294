export const searchParamsToObject = (search: string) => {
  let param = search;
  if (param.charAt(0) === '?') {
    param = param.substring(1);
  }

  const paramsKeyValues = param.split('&');
  const toReturn: { [key: string]: string } = {};

  for (let i = 0; i < paramsKeyValues.length; i += 1) {
    const [key, value] = paramsKeyValues[i].split('=');
    toReturn[key] = `${value}`;
  }
  return toReturn;
};
