import {
  IconArchive,
  IconArrowsLeftRight,
  IconBriefcase,
  IconBuildingBank,
  IconChartArea,
  IconChartLine,
  IconCoin,
  IconDeviceMobile,
  IconExchange,
  IconFileExport,
  IconFileText,
  IconFlag,
  IconGitBranch,
  IconId,
  IconInfoCircle,
  IconLink,
  IconList,
  IconListCheck,
  IconListSearch,
  IconPercentage,
  IconScale,
  IconSettings,
  IconUpload,
  IconUser,
  IconUserCheck,
  IconUsers,
  IconUserX,
  IconWorld,
  TablerIconProps,
  IconLock,
  IconMail,
  IconBrandGoogle,
  IconDna,
  IconDatabase,
  IconTicket,
  IconCreditCard,
  IconExternalLink,
  IconTrash,
} from '@u21/tabler-icons';
import { ReactComponent as PreviewIcon } from 'app/shared/svg/preview.svg';
import { ReactComponent as RuleIcon } from 'app/shared/svg/rule.svg';
import assets from 'assets/assets';

// Utils
import { getDOMProps } from 'app/shared/utils/react';
import { FC } from 'react';

const tablerIcons = {
  [assets.icons.alerts]: IconFlag,
  [assets.icons.cases]: IconBriefcase,
  [assets.icons.otherScenario]: IconArchive,
  [assets.icons.matchlists]: IconList,
  [assets.icons.statisticsScenario]: IconChartArea,
  [assets.icons.detectionModels]: IconScale,
  [assets.icons.infoCircle]: IconInfoCircle,
  [assets.icons.sarFilings]: IconBuildingBank,
  [assets.icons.structuringScenario]: IconPercentage,
  [assets.icons.insights]: IconChartLine,
  [assets.icons.blacklistScenario]: IconUserX,
  [assets.icons.uploads]: IconUpload,
  [assets.icons.cardinalityScenario]: IconArrowsLeftRight,
  [assets.icons.anomalyScenario]: IconListSearch,
  [assets.icons.entity]: IconUser,
  [assets.icons.devices]: IconDeviceMobile,
  [assets.icons.transactions]: IconExchange,
  [assets.icons.onDemandVerifications]: IconId,
  [assets.icons.batchFileVerifications]: IconFileText,
  [assets.icons.verificationWorkflows]: IconGitBranch,
  [assets.icons.agent]: IconUserCheck,
  [assets.icons.team]: IconUsers,
  [assets.icons.assignments]: IconListCheck,
  [assets.icons.webhooks]: IconLink,
  [assets.icons.systemWorkflows]: IconSettings,
  [assets.icons.fileExports]: IconFileExport,
  [assets.icons.exchange]: IconCoin,
  [assets.icons.admin]: IconWorld,
  [assets.icons.lock]: IconLock,
  [assets.icons.mail]: IconMail,
  [assets.icons.google]: IconBrandGoogle,
  [assets.icons.dna]: IconDna,
  [assets.icons.database]: IconDatabase,
  [assets.icons.ticket]: IconTicket,
  [assets.icons.creditCard]: IconCreditCard,
  [assets.icons.external]: IconExternalLink,
  [assets.icons.trash]: IconTrash,
};

interface U21IconProps extends TablerIconProps {
  assetIcon?: string;
}

const U21Icon: FC<U21IconProps> = (props) => {
  const {
    assetIcon,
    color = 'currentColor',
    stroke = 2,
    size = 20,
    ...rest
  } = props;

  if (!assetIcon) {
    return null;
  }

  if (assetIcon === 'preview') {
    return (
      <PreviewIcon
        height={size}
        stroke={color}
        strokeWidth={stroke}
        width={size}
        {...getDOMProps(rest)}
      />
    );
  }
  if (assetIcon === assets.icons.rules) {
    return (
      <RuleIcon
        height={size}
        stroke={color}
        strokeWidth={stroke}
        width={size}
        {...getDOMProps(rest)}
      />
    );
  }
  const Icon = tablerIcons[assetIcon];
  if (Icon) {
    return (
      <Icon
        color={color}
        height={size}
        stroke={stroke}
        width={size}
        {...getDOMProps(rest)}
      />
    );
  }
  return null;
};

export default U21Icon;
