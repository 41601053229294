import { U21Page, U21TabRouter } from '@u21/components';
import { IconSettings } from '@u21/tabler-icons';

import { ApiKeys } from 'features/settings/tabs/apiKeys/components/ApiKeys';
import { Agents } from 'features/settings/tabs/agents/components/Agents';
import { useAppSelector } from 'app/hooks';
import { hasPermissionsRequired } from 'features/session/utils';
import { selectPermissions } from 'features/agents/selectors';
import { ROUTES_MAP } from 'app/shared/utils/routes';
import { Redirect } from 'react-router';

export const Settings = () => {
  const agentPermissions = useAppSelector(selectPermissions);
  const TABS = [];

  if (
    hasPermissionsRequired(agentPermissions, ROUTES_MAP.apiKeys.permissions)
  ) {
    TABS.push({
      label: 'API Keys',
      component: <ApiKeys />,
      path: '/settings/api-keys',
      exact: true,
    });
  }
  if (hasPermissionsRequired(agentPermissions, ROUTES_MAP.agents.permissions)) {
    TABS.push({
      label: 'Agents',
      component: <Agents />,
      path: '/settings/agents',
      exact: true,
    });
  }
  if (TABS.length === 0) {
    return <Redirect to="/404" />;
  }
  return (
    <U21Page title="Settings" titleIcon={<IconSettings size={36} />}>
      <U21TabRouter tabs={TABS} />
    </U21Page>
  );
};
