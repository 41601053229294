import { ElementType } from 'react';
import { Route, Redirect } from 'react-router';
import { RouteProps } from 'react-router-dom';
import { useAuth } from 'providers/AuthProvider';
import { hasPermissionsRequired } from 'features/session/utils';
import { selectPermissions } from 'features/agents/selectors';
import { useAppSelector } from 'app/hooks';
import { selectAppLoading } from 'features/session/selectors';
import { U21Loading } from '@u21/components';
import styled from 'styled-components';

interface Props extends Omit<RouteProps, 'component'> {
  component: ElementType;
  requiredPermissions: string[];
  name: string;
}

export const ProtectedRoute = ({
  component: Component,
  requiredPermissions,
  name,
  ...restOfProps
}: Props) => {
  const { authClient: auth } = useAuth();
  const permissions = useAppSelector(selectPermissions);
  const appLoading = useAppSelector(selectAppLoading);
  if (!auth.isAuthenticated()) {
    return <Redirect to="/login" />;
  }
  if (appLoading) {
    return <StyledU21Loading loading />;
  }
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        hasPermissionsRequired(permissions, requiredPermissions) ? (
          <Component {...props} />
        ) : (
          <Redirect to="/404" />
        )
      }
    />
  );
};

const StyledU21Loading = styled(U21Loading)`
  height: 100%;
`;
