import { Agent, AgentPayload } from 'features/agents/models';

export const agentPayloadToAgent = (agentPayload: AgentPayload): Agent => ({
  id: agentPayload.id,
  fullName: agentPayload.full_name,
  email: agentPayload.email,
  status: agentPayload.status,
  lastSeen: agentPayload.last_seen,
  permissions: agentPayload.permissions.reduce((nextPerms, perm) => {
    return { ...nextPerms, [perm]: true };
  }, {}),
});
