import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from 'app/store';
import { U21Theme } from '@u21/components';
import { AuthProvider } from 'providers/AuthProvider';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'index.css';
import styled, { css } from 'styled-components';

import { AppRouter } from 'routers/AppRouter';

import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root')!;
const root = createRoot(container);

const StyledToastContainer = styled(ToastContainer).attrs({
  className: 'toast-container',
})`
  ${({ theme }) => css`
    --toastify-color-success: ${theme.palette.success.main};
    --toastify-color-warning: ${theme.palette.warning.main};
    --toastify-color-error: ${theme.palette.error.main};

    --toastify-icon-color-success: var(--toastify-color-success);
    --toastify-icon-color-warning: var(--toastify-color-warning);
    --toastify-icon-color-error: var(--toastify-color-error);

    --toastify-color-progress-success: var(--toastify-color-success);
    --toastify-color-progress-warning: var(--toastify-color-warning);
    --toastify-color-progress-error: var(--toastify-color-error);
  `}
`;

root.render(
  <Provider store={store}>
    <AuthProvider>
      <U21Theme>
        <StyledToastContainer />
        <AppRouter />
      </U21Theme>
    </AuthProvider>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
