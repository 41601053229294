import { useEffect } from 'react';
import { useAuth } from 'providers/AuthProvider';
import { initSession, setLastRoute } from 'features/session/sliceSession';
import { useAppDispatch } from './hooks';

export const AppContainer = ({ children }) => {
  const { authClient: auth } = useAuth();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const login = async () => {
      const url = window.location.pathname;
      dispatch(setLastRoute(url));

      if (auth.isAuthenticated()) {
        await auth.renewSession();
        dispatch(initSession(auth));
      }
    };

    login();
  }, [auth, dispatch]);

  return children;
};
