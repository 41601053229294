const isEmailValid = (email: string): boolean => {
  // email validation found here: https://www.w3resource.com/javascript/form/email-validation.php
  const emailValid =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      email,
    );

  return emailValid;
};

export default isEmailValid;
