import Cookies from 'js-cookie';
import { merge, cloneDeep } from 'lodash';

// Utils
import { consoleError } from 'app/shared/utils/console';
import { v4 } from 'uuid';

import { COOKIE_JWT_KEY } from 'app/shared/auth/magicLinkAuthService';
import { getApiDomain } from './getApiDomain';

const API_BASE = '/v1';
export interface EmptyPayload {}

const defaultOptions = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': 'True',
  },
  credentials: 'include',
};

const getAccessToken = () => {
  if (process.env.NODE_ENV === 'test') {
    return '';
  }

  return Cookies.get(COOKIE_JWT_KEY);
};

function checkStatus(response: Response): Promise<Response | null> {
  // 204 is no content. return null to trigger the the next 'then' condition

  if (response.status === 204) {
    return Promise.resolve(null);
  }

  if (response.status >= 200 && response.status < 300) {
    return Promise.resolve(response);
  }

  consoleError(
    `HTTP ${response.status} accessing "${response.url}". See the full Response object below:`,
  );

  return Promise.reject(response);
}

function generateQueryParamString(
  queryParams: { [key: string]: number }[],
): string {
  const resArr: string[] = [];
  queryParams.forEach((qp) => resArr.push(`${qp.key}=${qp.val}`));

  const res = resArr.join('&');
  return res ? `?${res}` : '';
}

const fetchr = async (
  endpoint: string,
  queryParams = [],
  _options = cloneDeep(defaultOptions),
  json = true,
): Promise<any> => {
  const accessToken: string = getAccessToken();
  const options: any = { ..._options };
  options.headers.Authorization = `Bearer ${accessToken}`;
  options.headers.RequestID = v4();

  const apiDomain = getApiDomain();

  return fetch(
    `${apiDomain}${API_BASE}${endpoint}${generateQueryParamString(
      queryParams,
    )}`,
    options,
  )
    .then(checkStatus)
    .then((res) => {
      if (res) {
        return json ? res.json() : res.text();
      }
      return {};
    });
};

export const downloadFile = async (
  endpoint: string,
  fileName: string,
  method: string = 'GET',
) => {
  const accessToken: string = await getAccessToken();
  const options: any = cloneDeep(defaultOptions);
  options.method = method;
  options.headers.Authorization = `Bearer ${accessToken}`;
  options.headers.RequestID = v4();

  const apiDomain = getApiDomain();

  return fetch(`${apiDomain}${API_BASE}${endpoint}`, options)
    .then(checkStatus)
    .then((res: any) => {
      if (res) {
        res
          .blob()
          .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.style.display = 'none';
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
          })
          .catch((error) => {
            consoleError('Failed to downoad file. TODO: Toast Message');
            throw new Error(`Error occurred:${error}`);
          });
      }
      return {};
    });
};

export async function get(endpoint: string, queryParams = [], config = {}) {
  const options: any = merge(cloneDeep(defaultOptions), { ...config });
  options.method = 'GET';

  return fetchr(endpoint, queryParams, options);
}

export async function post(endpoint: string, body = {}, config = {}) {
  const options: any = merge(cloneDeep(defaultOptions), { ...config });
  options.method = 'POST';
  options.body = JSON.stringify(body);

  return fetchr(endpoint, [], options);
}

export async function put(endpoint: string, body = {}, config = {}) {
  const options: any = merge(cloneDeep(defaultOptions), { ...config });
  options.method = 'PUT';
  options.body = JSON.stringify(body);

  return fetchr(endpoint, [], options);
}

export async function destroy(endpoint: string, config = {}) {
  const options: any = merge(cloneDeep(defaultOptions), { ...config });
  options.method = 'DELETE';

  return fetchr(endpoint, [], options);
}

export const upload = async (endpoint: string, body: FormData) => {
  const accessToken: string = await getAccessToken();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    RequestID: v4(),
  };

  return fetch(`${API_BASE}${endpoint}`, {
    method: 'POST',
    headers,
    body,
  }).then((response) => {
    if (response.status !== 200) {
      return Promise.reject(response);
    }
    return response.json();
  });
};
