import { U21Card, U21FileUploader, U21Page, U21Spacer } from '@u21/components';
import { IconListSearch } from '@u21/tabler-icons';
import {
  setFilesToUploadAsync,
  uploadDataFileThunk,
} from 'features/fileUploads/sliceFileUploads';
import { useAppDispatch } from 'app/hooks';
import { UploadFile } from 'features/fileUploads/models';
import { FileExplorerCard } from 'features/fileUploads/components/FileExplorerCard';
import { toastError } from 'app/shared/toast';

export const FileUploads = () => {
  document.title = 'Fintech Fraud DAO - File Uploads';

  const DOCUMENT_UPLOAD_LIMIT = 200;

  const dispatch = useAppDispatch();

  const onDrop = (acceptedFiles: File[], rejectedFiles) => {
    if (acceptedFiles.length > DOCUMENT_UPLOAD_LIMIT) {
      toastError(
        `Exceeded file upload limit. Please upload less than ${DOCUMENT_UPLOAD_LIMIT} files at a time.`,
      );
      return;
    }

    const rejectedNames = rejectedFiles.map((file) => file.name);
    const rejectedFilesMessage = rejectedNames.join(', ');
    if (rejectedFilesMessage) {
      toastError(
        `Error uploading the following file(s): ${rejectedFilesMessage}`,
      );
    }

    const filesToUpload: UploadFile[] = acceptedFiles.map((file: File) => {
      dispatch(uploadDataFileThunk(file));
      return {
        file_name: file.name,
        status: 'pending',
        size: file.size,
      };
    });
    dispatch(setFilesToUploadAsync(filesToUpload));
  };

  return (
    <U21Page title="File Uploads" titleIcon={<IconListSearch />}>
      <U21Spacer spacing={2}>
        <U21Card title="Upload Data File">
          <U21FileUploader accept={['.csv']} onDrop={onDrop} />
        </U21Card>
        <FileExplorerCard />
      </U21Spacer>
    </U21Page>
  );
};
