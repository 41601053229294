import { APIKey, APIKeyPayload } from 'features/settings/models';

export const apiKeyPayloadToAPIKey = (
  apiKeyPayload: APIKeyPayload,
): APIKey => ({
  id: apiKeyPayload.id,
  label: apiKeyPayload.label,
  prefix: apiKeyPayload.prefix,
  createdAt: apiKeyPayload.created_at,
});
