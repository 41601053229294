import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { AppContainer } from 'app/AppContainer';
import { Login } from 'features/session/Login';
import { EmailTokenExchange } from 'features/session/EmailTokenExchange';
import { Home } from 'app/shared/components/Home';
import { SearchRecords } from 'features/searchRecords/components/SearchRecords';
import { FileUploads } from 'features/fileUploads/components/FileUploads';
import { Settings } from 'features/settings/Settings';
import FourOhFour from 'app/shared/components/FourOhFour';

import 'react-toastify/dist/ReactToastify.css';

// Utils
import { ProtectedRoute } from 'routers/ProtectedRoute';
import { StyledContainer } from 'app/StyledContainer';
import { ROUTES_MAP } from 'app/shared/utils/routes';

export const AppRouter = () => {
  return (
    <AppContainer>
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/email-login" component={EmailTokenExchange} />
          <Route exact path="/404" component={FourOhFour} />

          <StyledContainer>
            <ProtectedRoute
              exact
              path={ROUTES_MAP.home.path}
              name={ROUTES_MAP.home.name}
              requiredPermissions={ROUTES_MAP.home.permissions}
              component={Home}
            />
            <ProtectedRoute
              exact
              path={ROUTES_MAP.searchRecords.path}
              name={ROUTES_MAP.searchRecords.name}
              requiredPermissions={ROUTES_MAP.searchRecords.permissions}
              component={SearchRecords}
            />
            <ProtectedRoute
              exact
              path={ROUTES_MAP.fileUploads.path}
              name={ROUTES_MAP.fileUploads.name}
              requiredPermissions={ROUTES_MAP.fileUploads.permissions}
              component={FileUploads}
            />

            {/* Settings */}
            <ProtectedRoute
              exact
              path="/settings"
              name="Settings"
              component={Settings}
              requiredPermissions={[]}
            />
            <ProtectedRoute
              exact
              path={ROUTES_MAP.apiKeys.path}
              name={ROUTES_MAP.apiKeys.name}
              requiredPermissions={ROUTES_MAP.apiKeys.permissions}
              component={Settings}
            />
            <ProtectedRoute
              exact
              path={ROUTES_MAP.agents.path}
              name={ROUTES_MAP.agents.name}
              requiredPermissions={ROUTES_MAP.agents.permissions}
              component={Settings}
            />
          </StyledContainer>
        </Switch>
      </BrowserRouter>
    </AppContainer>
  );
};
