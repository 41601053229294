import { RootState } from 'app/store';

export const selectUploads = (state: RootState) => {
  return [
    ...(state.fileUpload.filesToUploadAsync || []),
    ...(state.fileUpload.uploads || []),
  ];
};

export const selectRetrieveDataFilesLoading = (state: RootState) =>
  state.fileUpload.loadingRetrieveDataFiles;

export const selectDataFilesCount = (state: RootState) =>
  state.fileUpload.count;
