export const permissions = {
  createApiKeys: 'CREATE_API_KEYS',
  readApiKeys: 'READ_API_KEYS',
  updateApiKeys: 'UPDATE_API_KEYS',
  deleteApiKeys: 'DELETE_API_KEYS',

  createAgents: 'CREATE_AGENTS',
  readAgents: 'READ_AGENTS',
  updateAgents: 'UPDATE_AGENTS',
  deleteAgents: 'DELETE_AGENTS',
};
