/* eslint-disable no-param-reassign */
import { PayloadAction } from '@reduxjs/toolkit';
import { retrieveDataFiles, uploadDataFile } from 'features/fileUploads/api';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';

import { UploadFile } from 'features/fileUploads/models';
import Cookies from 'js-cookie';
import { PaginationPayload } from 'app/shared/utils/table';
import { toastError, toastSuccess, toastWarning } from 'app/shared/toast';
import pluralize from 'pluralize';

const FILE_UPLOADS_NAME = 'fileUploadsSlice';
const COOKIE_JWT_KEY = 'unit21_jwt';

interface UploadState {
  uploads: UploadFile[];
  filesToUploadAsync: UploadFile[];
  loadingRetrieveDataFiles: boolean;
  count: number;
}

const initialState: UploadState = {
  uploads: [],
  filesToUploadAsync: [],
  loadingRetrieveDataFiles: false,
  count: 0,
};

export interface UploadFileProgress {
  file_name: string;
  percent: number;
  total: number;
}

export interface ListFileUploadPayload {
  files: UploadFile[];
  count: number;
}

export const retrieveDataFilesThunk = u21CreateAsyncThunk(
  `${FILE_UPLOADS_NAME}/RETRIEVE_DATA_FILE`,
  (payload: PaginationPayload) => {
    return retrieveDataFiles(payload);
  },
);

export const uploadDataFileThunk = u21CreateAsyncThunk(
  `${FILE_UPLOADS_NAME}/UPLOAD_DATA_FILE`,
  async (payload: File) => {
    const accessToken = Cookies.get(COOKIE_JWT_KEY); // to replace
    await uploadDataFile(accessToken, payload);
  },
);

const fileUploadsSlice = u21CreateSlice({
  name: FILE_UPLOADS_NAME,
  initialState,
  reducers: {
    setFilesToUploadAsync: (draft, action) => {
      draft.filesToUploadAsync = action.payload.map((file) => ({
        ...file,
        id: 0,
        uploaded_at: new Date().toUTCString(),
        status: 'Uploading',
        file_name: file.file_name,
      }));
    },
    setFileUploadProgress: (draft, action) => {
      draft.filesToUploadAsync.forEach((upload) => {
        if (upload.file_name === action.payload.file_name) {
          upload.percent = action.payload.percent;
          upload.file_size = action.payload.total;
        }
      });
    },
    setFileUploadSuccess: (draft, action: PayloadAction<UploadFile>) => {
      const { total_rows: totalRows, skipped_rows: skippedRows } =
        action.payload;

      if (totalRows > 0) {
        const message = `Uploaded ${totalRows} ${pluralize('row', totalRows)}`;
        if (skippedRows > 0) {
          toastWarning(
            `${message}, skipped ${skippedRows} ${pluralize(
              'row',
              skippedRows,
            )}`,
          );
        } else {
          toastSuccess(message);
        }
      } else {
        toastError(
          'Could not upload any rows from file. Please make sure the data is hashed correctly.',
        );
      }

      draft.filesToUploadAsync = draft.filesToUploadAsync.filter(
        (upload) => upload.file_name !== action.payload.file_name,
      );
      draft.uploads = [action.payload, ...draft.uploads];
    },
    setFileUploadError: (draft, action) => {
      draft.filesToUploadAsync.forEach((upload) => {
        if (upload.file_name === action.payload.file_name) {
          upload.status = 'Upload failed';
          upload.error_message = action.payload.error_message;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addLoadingCase(
      retrieveDataFilesThunk,
      'loadingRetrieveDataFiles',
      (draft, action) => {
        draft.uploads = action.payload.files;
        draft.count = action.payload.count;
      },
    );
  },
});

export const {
  setFileUploadProgress,
  setFilesToUploadAsync,
  setFileUploadSuccess,
  setFileUploadError,
} = fileUploadsSlice.actions;
export default fileUploadsSlice.reducer;
