import {
  SemanticICONS,
  SemanticCOLORS,
} from 'semantic-ui-react/dist/commonjs/generic';
import { IconProps } from 'semantic-ui-react';
import daoLogoTitleless from 'assets/images/logos/daoLogoTitleless.png';
import daoLogo from 'assets/images/logos/daoLogo.svg';
import emailCloud from 'assets/images/email-cloud.png';

type InternalIconNames =
  | 'archive'
  | 'unarchive'
  | 'devices'
  | 'admin'
  | 'alerts'
  | 'add'
  | 'addOutline'
  | 'angleLeft'
  | 'angleRight'
  | 'areaChart'
  | 'arrowLeft'
  | 'arrowRight'
  | 'ban'
  | 'barChart'
  | 'bell'
  | 'briefcase'
  | 'bulkAction'
  | 'caretDown'
  | 'caretUp'
  | 'cases'
  | 'chain'
  | 'chartLine'
  | 'insights'
  | 'chartPie'
  | 'check'
  | 'assignments'
  | 'checkeredFlag'
  | 'checkmark'
  | 'chevronLeft'
  | 'chevronRight'
  | 'childOrg'
  | 'circle'
  | 'close'
  | 'closeOutline'
  | 'conversation'
  | 'copy'
  | 'creditCard'
  | 'csvFile'
  | 'database'
  | 'doubleUpArrow'
  | 'dropdown'
  | 'edit'
  | 'ellipsisVertical'
  | 'entity'
  | 'exchange'
  | 'externalAlternate'
  | 'factory'
  | 'file'
  | 'folder'
  | 'gavel'
  | 'handSpockOutline'
  | 'idCard'
  | 'imagesOutline'
  | 'infoCircle'
  | 'law'
  | 'linkify'
  | 'list'
  | 'mail'
  | 'money'
  | 'minus'
  | 'outlinedCheck'
  | 'circleCheck'
  | 'pencil'
  | 'pdfFile'
  | 'question'
  | 'redoAlternate'
  | 'rules'
  | 'save'
  | 'search'
  | 'settings'
  | 'simpleList'
  | 'slack'
  | 'sync'
  | 'spy'
  | 'table'
  | 'tag'
  | 'trash'
  | 'user'
  | 'userOutline'
  | 'userCircle'
  | 'userProfile'
  | 'warning'
  | 'zippedFile'
  | 'external'
  | 'internal'
  | 'hit'
  | 'puzzle'
  | 'goto'
  | 'team'
  | 'agent'
  | 'eye'
  | 'eyeSlash'
  | 'emptyAssignments'
  | 'sarFilings'
  | 'systemWorkflows'
  | 'matchlists'
  | 'uploads'
  | 'transactions'
  | 'onDemandVerifications'
  | 'batchFileVerifications'
  | 'verificationWorkflows'
  | 'webhooks'
  | 'fileExports'
  | 'undelete'
  | 'detectionModels'
  | 'geolocations'
  | 'simpleDetectionModel'
  | 'advancedDetectionModel'
  | 'structuringScenario'
  | 'anomalyScenario'
  | 'cardinalityScenario'
  | 'statisticsScenario'
  | 'blacklistScenario'
  | 'otherScenario'
  | 'time'
  | 'mapMarker'
  | 'fileAlternate'
  | 'scrub'
  | 'lock'
  | 'google'
  | 'dna'
  | 'ticket';

const semanticIcons: { [key in InternalIconNames]: SemanticICONS } = {
  simpleDetectionModel: 'balance scale',
  advancedDetectionModel: 'code',
  devices: 'mobile alternate',
  add: 'add',
  addOutline: 'plus square outline',
  alerts: 'flag checkered',
  admin: 'globe',
  angleLeft: 'angle left',
  angleRight: 'angle right',
  areaChart: 'chart area',
  arrowLeft: 'arrow left',
  arrowRight: 'arrow right',
  ban: 'ban',
  barChart: 'chart bar outline',
  bell: 'bell outline',
  bulkAction: 'server',
  briefcase: 'briefcase',
  caretDown: 'caret down',
  caretUp: 'caret up',
  cases: 'briefcase',
  chain: 'chain',
  chartLine: 'chart line',
  childOrg: 'child',
  insights: 'chart line',
  chartPie: 'chart pie',
  check: 'check',
  assignments: 'clipboard check',
  emptyAssignments: 'clipboard',
  checkeredFlag: 'flag checkered',
  checkmark: 'checkmark',
  chevronLeft: 'chevron circle left',
  chevronRight: 'chevron circle right',
  circle: 'circle',
  close: 'close',
  closeOutline: 'times circle outline',
  conversation: 'conversation',
  copy: 'copy outline',
  creditCard: 'credit card outline',
  csvFile: 'file excel',
  database: 'database',
  doubleUpArrow: 'angle double up',
  dropdown: 'dropdown',
  edit: 'edit outline',
  ellipsisVertical: 'ellipsis vertical',
  entity: 'street view',
  exchange: 'exchange',
  externalAlternate: 'external alternate',
  factory: 'factory',
  file: 'file alternate outline',
  folder: 'folder outline',
  gavel: 'gavel',
  handSpockOutline: 'hand spock outline',
  idCard: 'id card outline',
  imagesOutline: 'images outline',
  infoCircle: 'info circle',
  law: 'law',
  linkify: 'linkify',
  webhooks: 'linkify',
  list: 'list alternate outline',
  mail: 'mail outline',
  money: 'money',
  outlinedCheck: 'check circle outline',
  circleCheck: 'check circle',
  pencil: 'pencil',
  pdfFile: 'file pdf',
  question: 'question',
  redoAlternate: 'redo alternate',
  save: 'save',
  search: 'search',
  settings: 'settings',
  simpleList: 'list',
  slack: 'slack hash',
  sync: 'sync alternate',
  spy: 'spy',
  table: 'table',
  tag: 'tag',
  trash: 'trash alternate outline',
  user: 'user',
  userCircle: 'user circle',
  userProfile: 'user circle outline',
  warning: 'exclamation triangle',
  zippedFile: 'file archive outline',
  external: 'external square',
  internal: 'home',
  hit: 'bolt',
  puzzle: 'puzzle',
  goto: 'share square',
  team: 'users',
  agent: 'user',
  eye: 'eye',
  eyeSlash: 'eye slash',
  sarFilings: 'university',
  systemWorkflows: 'cogs',
  minus: 'minus square outline',
  rules: 'gavel',
  matchlists: 'list ul',
  uploads: 'upload',
  userOutline: 'user outline',
  transactions: 'exchange',
  onDemandVerifications: 'id card',
  batchFileVerifications: 'file alternate outline',
  verificationWorkflows: 'fork',
  fileExports: 'file archive',
  undelete: 'undo',
  archive: 'file archive outline',
  unarchive: 'undo',
  geolocations: 'map signs',
  detectionModels: 'balance scale',
  time: 'time',
  mapMarker: 'map marker alternate',
  fileAlternate: 'file alternate',
  structuringScenario: 'percent',
  anomalyScenario: 'user secret',
  cardinalityScenario: 'arrows alternate',
  statisticsScenario: 'area chart',
  blacklistScenario: 'list ul',
  otherScenario: 'archive',
  scrub: 'th',
  lock: 'lock',
  google: 'google',
  dna: 'dna',
  ticket: 'ticket',
};

type InternalSignNames = 'error' | 'completed' | 'info' | 'warning';

const signs: { [key in InternalSignNames]: IconProps } = {
  error: {
    circular: true,
    size: 'small',
    color: 'red' as SemanticCOLORS,
    name: semanticIcons.close,
  },
  warning: {
    color: 'orange' as SemanticCOLORS,
    name: semanticIcons.warning,
  },
  completed: {
    circular: true,
    size: 'small',
    color: 'green' as SemanticCOLORS,
    name: semanticIcons.check,
  },
  info: {
    circular: true,
    size: 'small',
    color: 'teal' as SemanticCOLORS,
    name: semanticIcons.infoCircle,
  },
};

const assets = {
  images: {
    daoLogo,
    daoLogoTitleless,
    emailCloud,
  },
  icons: semanticIcons,
  signs,
};

export default assets;
