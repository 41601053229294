import { useContext, useState, createContext, useMemo } from 'react';

import { AuthClient } from 'app/shared/auth/models';

import { MagicLinkAuthService } from 'app/shared/auth/magicLinkAuthService';

interface AuthProviderProps {
  children: JSX.Element;
}

const MAGIC_LINK_AUTH_SERVICE: AuthClient = new MagicLinkAuthService();

export interface AuthContextState {
  authClient: AuthClient;
  setAuthClient: (authClient: AuthClient) => void;
}

export const AuthContext = createContext<AuthContextState>({
  authClient: MAGIC_LINK_AUTH_SERVICE,
  setAuthClient: () => {},
});

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [authClient, setAuthClient] = useState(MAGIC_LINK_AUTH_SERVICE);

  const state: AuthContextState = useMemo(
    () => ({
      authClient,
      setAuthClient,
    }),
    [authClient],
  );

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
