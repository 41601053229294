import React, { useState } from 'react';
import {
  U21Button,
  U21Modal,
  U21Form,
  U21FormField,
  U21Spacer,
} from '@u21/components';
import { IconThumbUp } from '@u21/tabler-icons';

import { useAppDispatch } from 'app/hooks';
import isEmailValid from 'app/shared/utils/isEmailValid';
import { createAgent } from 'features/settings/sliceSettings';

const FORM_ID = 'add-new-agent-form';

interface OwnProps {
  disabled: boolean;
  children: React.ReactNode;
}

export const AddNewAgentButton = ({ disabled, children }: OwnProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useAppDispatch();

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const createAgentHandler = (model) => {
    const { fullName, email } = model;
    dispatch(createAgent({ fullName, email }));
    closeModal();
  };

  return (
    <>
      <U21Button disabled={disabled} onClick={openModal}>
        {children}
      </U21Button>
      <U21Modal
        title="Add New Agent"
        open={modalOpen}
        onClose={closeModal}
        actionButtonProps={{
          children: 'Add agent',
          color: 'success',
          startIcon: <IconThumbUp />,
          type: 'submit',
          form: FORM_ID,
        }}
      >
        <U21Form id={FORM_ID} onSubmit={createAgentHandler}>
          <U21Spacer spacing="16px">
            <U21FormField
              required
              name="fullName"
              fieldProps={{
                clearable: true,
                label: 'Full name',
              }}
              type="TEXT"
              validate={(value) =>
                (value || '').split(' ').length >= 2
                  ? undefined
                  : 'Please enter a first and last name'
              }
            />
            <U21FormField
              required
              name="email"
              fieldProps={{
                clearable: true,
                label: 'Email',
              }}
              type="TEXT"
              validate={(value) =>
                isEmailValid(value) ? undefined : 'Please enter a valid email'
              }
            />
          </U21Spacer>
        </U21Form>
      </U21Modal>
    </>
  );
};
