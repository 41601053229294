import { useEffect } from 'react';
import { useLocation, useHistory, RouteComponentProps } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useAuth } from 'providers/AuthProvider';

// Components
import { U21Loading } from '@u21/components';

// Thunks
import { emailTokenExchangeThunk } from 'features/session/sliceSession';
import { selectEmailTokenExchangeLoading } from 'features/session/selectors';

// Utils
import { searchParamsToObject } from 'app/shared/utils/searchParamsToObjects';
import styled from 'styled-components';

export const EmailTokenExchange = () => {
  const { search } = useLocation();
  const history: RouteComponentProps['history'] = useHistory();
  const { token } = searchParamsToObject(search);
  const { authClient: auth } = useAuth();
  const emailTokenExchangeLoading = useAppSelector(
    selectEmailTokenExchangeLoading,
  );

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(emailTokenExchangeThunk({ token, auth, history }));
  }, [dispatch, token, auth, history]);

  if (!search || !token) {
    window.location.replace('/');
    return null;
  }

  return <StyledU21Loading loading={emailTokenExchangeLoading} />;
};

const StyledU21Loading = styled(U21Loading)`
  height: 100%;
`;
