import { useState } from 'react';
import styled from 'styled-components';
import { U21Modal } from '@u21/components';
import { IconX, IconThumbUp } from '@u21/tabler-icons';
import { useAppDispatch } from 'app/hooks';
import { deleteApiKey } from 'features/settings/sliceSettings';

interface Props {
  id: string;
  prefix: string;
}

export const DeactivateApiKey = (props: Props) => {
  const { id, prefix } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useAppDispatch();

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const deactivateApiKey = () => {
    dispatch(deleteApiKey(id));
    closeModal();
  };

  return (
    <>
      <Container onClick={openModal}>
        <IconX />
      </Container>
      <U21Modal
        title="Delete API Key"
        open={modalOpen}
        onClose={closeModal}
        actionButtonProps={{
          children: 'Delete',
          color: 'error',
          startIcon: <IconThumbUp />,
        }}
        onAction={deactivateApiKey}
      >
        Delete API key starting with {prefix}?
      </U21Modal>
    </>
  );
};

const Container = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`;
