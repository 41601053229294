import React from 'react';
import { ReactComponent as LogoSVG } from 'assets/images/logos/daoLogoRed.svg';

interface StyleMap {
  [s: string]: React.CSSProperties | StyleMap;
}
interface FraudDAOLogoProps {
  styleProps?: StyleMap;
}

const FraudDAOLogoRed = ({ styleProps }: FraudDAOLogoProps) => {
  const mergedStyles = { ...styles.default, ...(styleProps || {}) };
  return <LogoSVG style={mergedStyles} />;
};

const styles: StyleMap = {
  default: {
    width: '80%',
    height: '100%',
  },
};

export default React.memo(FraudDAOLogoRed);
