import { RootState } from 'app/store';

export const selectLoadingApiKeys = (state: RootState) =>
  state.settings.loadingApiKeys;

export const selectApiKeys = (state: RootState) => state.settings.apiKeys;

export const selectNewApiKey = (state: RootState) => ({
  newUnhashedApiKey: state.settings.newUnhashedApiKey,
  newAPIKeyId: state.settings.newAPIKeyId,
});

export const selectAgents = (state: RootState) => state.settings.agents;

export const selectLoadingAgents = (state: RootState) =>
  state.settings.loadingAgents;
