import { toast, ToastOptions } from 'react-toastify';

const toastProps: ToastOptions = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const toastSuccess = (message: string) => {
  toast.success(message, toastProps);
};

export const toastWarning = (message: string) => {
  toast.warning(message, toastProps);
};

export const toastError = (message: string) => {
  toast.error(message, toastProps);
};
