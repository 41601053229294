import { U21Page, U21Spacer } from '@u21/components';
import { IconFileSearch } from '@u21/tabler-icons';
import { useAppDispatch } from 'app/hooks';
import { useLayoutEffect } from 'react';
import { resetSearchResults } from 'features/searchRecords/sliceRecords';
import { RecordsSearchBar } from 'features/searchRecords/components/RecordsSearchBar';
import { RecordTableCard } from 'features/searchRecords/components/RecordTableCard';

export const SearchRecords = () => {
  const dispatch = useAppDispatch();
  useLayoutEffect(
    () => () => {
      dispatch(resetSearchResults());
    },
    [dispatch],
  );
  document.title = 'Fintech Fraud DAO - Search Records';
  return (
    <U21Page title="Search Records" titleIcon={<IconFileSearch />}>
      <U21Spacer spacing={2}>
        <RecordsSearchBar />
        <RecordTableCard />
      </U21Spacer>
    </U21Page>
  );
};
