import { UploadFile } from 'features/fileUploads/models';

import styled from 'styled-components';

import { U21Progress, U21Spacer } from '@u21/components';

interface OwnProps {
  row: UploadFile;
}

export const UploadStatus = (props: OwnProps) => {
  const { row } = props;
  const percent = Math.round(row.percent || 0);

  return (
    <Status $status={row.status} horizontal={row.status === 'Uploading'}>
      <span>{row.status}</span>
      {row.status === 'Upload Failed' && <>({row.error_message})</>}
      {row.status === 'Uploading' && (
        <U21Progress percentage value={percent} variant="circular" />
      )}
    </Status>
  );
};

interface StyleProps {
  $status: string;
}

const Status = styled(U21Spacer)<StyleProps>`
  color: ${(props) => {
    switch (props.$status) {
      case 'Uploading':
        return props.theme.palette.primary.main;
      case 'File successfully uploaded':
        return 'green';
      case 'Upload failed':
        return 'red';
      default:
        return '';
    }
  }};
`;
