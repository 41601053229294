import { U21Card, U21Table, U21TitleCountLabel } from '@u21/components';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RECORD_TABLE_COLUMNS } from 'features/searchRecords/columns';
import { useEffect } from 'react';
import {
  selectEntities,
  selectEntitiesLoading,
  selectSearchQuery,
} from 'features/searchRecords/selectors';
import { retrieveEntitiesThunk } from 'features/searchRecords/sliceRecords';

export const RecordTableCard = () => {
  const entities = useAppSelector(selectEntities);
  const loadingEntities = useAppSelector(selectEntitiesLoading);
  const searchQuery = useAppSelector(selectSearchQuery);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(retrieveEntitiesThunk({ searchQuery }));
  }, [dispatch, searchQuery]);

  return (
    <U21Card
      title={
        <U21TitleCountLabel count={entities.length} label="platform">
          Results
        </U21TitleCountLabel>
      }
    >
      <U21Table
        columns={RECORD_TABLE_COLUMNS}
        data={entities}
        loading={loadingEntities}
        filters={searchQuery}
        count={entities.length}
      />
    </U21Card>
  );
};
