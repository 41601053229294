import { createSlice } from '@reduxjs/toolkit';
import { Agent } from 'features/agents/models';

const AGENT_NAME = 'agentSlice';

export const initialState: Agent = {
  id: '',
  fullName: '',
  email: '',
  lastSeen: null,
  status: '',
  permissions: {},
};

const agentSlice = createSlice({
  name: AGENT_NAME,
  initialState,
  reducers: {
    updateAgent: (draft, { payload }) => {
      draft.id = payload.id;
      draft.fullName = payload.full_name;
      draft.email = payload.email;
      draft.permissions = payload.permissions.reduce((nextPerms, perm) => {
        return { ...nextPerms, [perm]: true };
      }, {});
    },
  },
});

export const agentSliceName = agentSlice.name;
export const { updateAgent } = agentSlice.actions;
export default agentSlice.reducer;
