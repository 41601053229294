import {
  U21Card,
  U21NoData,
  U21Table,
  U21TitleCountLabel,
  U21TableState,
} from '@u21/components';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { FILE_EXPLORER_COLUMNS } from 'features/fileUploads/columns';

import {
  selectDataFilesCount,
  selectRetrieveDataFilesLoading,
  selectUploads,
} from 'features/fileUploads/selectors';
import { retrieveDataFilesThunk } from 'features/fileUploads/sliceFileUploads';
import { createPaginationPayload } from 'app/shared/utils/table';

export const FileExplorerCard = () => {
  const dataFiles = useAppSelector(selectUploads);
  const loadingDataFiles = useAppSelector(selectRetrieveDataFilesLoading);
  const count = useAppSelector(selectDataFilesCount);
  const dispatch = useAppDispatch();

  const onStateChange = (state: U21TableState) => {
    dispatch(
      retrieveDataFilesThunk({
        ...createPaginationPayload(state),
      }),
    );
  };
  return (
    <U21Card
      title={
        <U21TitleCountLabel count={count} label="files">
          All files
        </U21TitleCountLabel>
      }
    >
      <U21Table
        actions={[]}
        columns={FILE_EXPLORER_COLUMNS}
        count={count}
        data={dataFiles}
        loading={loadingDataFiles}
        onStateChange={onStateChange}
        onRefresh={onStateChange}
        manualPagination
        noDataComponent={
          <U21NoData>
            No files found.. Start by uploading new csv file
          </U21NoData>
        }
        defaultSortBy={[{ desc: true, id: 'uploaded_at' }]}
      />
    </U21Card>
  );
};
