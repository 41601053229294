import { U21TableBodyCellProps, U21TableColumnTypes } from '@u21/components';
import { DeactivateApiKey } from 'features/settings/tabs/apiKeys/components/DeactivateApiKey';
import { APIKey } from 'features/settings/models';

const LABEL = {
  id: 'label',
  Header: 'Label',
  accessor: 'label',
  type: U21TableColumnTypes.TEXT,
};
const PREFIX = {
  id: 'prefix',
  Header: 'Prefix',
  accessor: 'prefix',
  Cell: (props: U21TableBodyCellProps<APIKey, string>) => {
    const { value } = props;
    return `${(value || '').substring(0, 4)}****`;
  },
  type: U21TableColumnTypes.TEXT,
};
const CREATED = {
  id: 'createdAt',
  Header: 'Created',
  accessor: 'createdAt',
  type: U21TableColumnTypes.DATETIME,
};
const DEACTIVATE = {
  id: 'deactivate',
  cellProps: (row: APIKey) => {
    return {
      children: <DeactivateApiKey {...row} />,
    };
  },
  type: U21TableColumnTypes.BUTTON,
};

export const API_KEYS_COLUMNS = [LABEL, PREFIX, CREATED, DEACTIVATE];
