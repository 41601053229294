import { get, destroy, post, put } from 'app/shared/utils/fetchr';
import { AgentPayload } from 'features/agents/models';
import { APIKeyPayload, CreateApiKeyPayload } from 'features/settings/models';

export const getOrgApiKeys = async (): Promise<APIKeyPayload[]> => {
  return get('/org/api-keys');
};

export const deleteApiKey = async (id: string): Promise<string> => {
  await destroy(`/org/api-key/${id}`);
  return id;
};

export const createApiKey = async (): Promise<CreateApiKeyPayload> => {
  return post('/org/api-key');
};

export const addApiKeyLabel = async (
  id: string,
  label: string,
): Promise<APIKeyPayload> => {
  return put(`/org/api-key/${id}`, { label });
};

export const getAllAgents = async (): Promise<AgentPayload[]> => {
  return get('/agents');
};

export const createAgent = async (
  fullName: string,
  email: string,
): Promise<AgentPayload> => {
  return post('/agents', { full_name: fullName, email });
};
