import { U21Spacer, U21Typography } from '@u21/components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const FourOhFour = () => {
  return (
    <Container>
      <StyledU21Spacer spacing={1}>
        <U21Typography variant="h2">404</U21Typography>
        <U21Typography variant="body1">Page not found</U21Typography>
        <Link to="/">Take me home</Link>
      </StyledU21Spacer>
    </Container>
  );
};

const Container = styled('div')`
  height: 90vh;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledU21Spacer = styled(U21Spacer)`
  align-items: center;
`;

export default FourOhFour;
