import { store } from 'app/store';
import Cookies from 'js-cookie';
import { BaseAuthService } from 'app/shared/auth/baseAuthService';
import { ExchangeAuthEmailTokenPayload } from 'features/session/api';
import { setAccessToken } from 'features/session/sliceSession';
import { MILLISECONDS_CONVERSION } from 'app/shared/constants';
import { getApiDomain } from '../utils/getApiDomain';

export const COOKIE_JWT_KEY = 'unit21_jwt';
const ONE_MINUTE_IN_SECONDS = 60;
const MAGIC_LINK_SESSION = 'magic_link_session';

export class MagicLinkAuthService extends BaseAuthService {
  tokenRenewalTimeout;

  tokenPingInterval = -1;

  provider = 'magic_link';

  expiresAt = -1;

  hasRefreshedToken = false;

  public renewSession = async () => {
    try {
      const apiDomain = getApiDomain();
      const response = await fetch(`${apiDomain}/v1/refresh-jwt`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'True',
        },
        credentials: 'include',
      });

      if (!response.ok) {
        await this.logout();
        return;
      }

      const data = await response.json();
      await this.login(data);

      this.hasRefreshedToken = true;
    } catch {
      await this.logout();
    }
  };

  // eslint-disable-next-line class-methods-use-this
  public isAuthenticated = () => {
    return Boolean(Cookies.get(MAGIC_LINK_SESSION));
  };

  public login = async (authPayload: ExchangeAuthEmailTokenPayload) => {
    const {
      jwt,
      jwt_expires_at: jwtExpiresAt,
      refresh_token_expires: refreshTokenExpires,
    } = authPayload;

    if (this.isAuthenticated()) {
      Cookies.remove(COOKIE_JWT_KEY);
      Cookies.remove(MAGIC_LINK_SESSION);
    }

    Cookies.set(COOKIE_JWT_KEY, jwt, {
      expires: new Date(jwtExpiresAt * MILLISECONDS_CONVERSION),
    });

    Cookies.set(MAGIC_LINK_SESSION, true, {
      expires: new Date(refreshTokenExpires * MILLISECONDS_CONVERSION),
    });

    this.expiresAt = jwtExpiresAt;

    store.dispatch(setAccessToken(jwt));
    this.expiresAt = jwtExpiresAt;
    this.scheduleRenewal();
  };

  public logout = async () => {
    clearTimeout(this.tokenRenewalTimeout);

    const jwtKey = Cookies.get(COOKIE_JWT_KEY);
    Cookies.remove(COOKIE_JWT_KEY);
    Cookies.remove(MAGIC_LINK_SESSION);

    if (jwtKey) {
      try {
        await fetch(`/v1/logout`, {
          method: 'POST',
          headers: { Authorization: `Bearer ${jwtKey}` },
        });
      } catch {}
    }

    window.location.replace('/login');
  };

  private scheduleRenewal = () => {
    // one min before the toke expires
    const timeout /* seconds */ =
      this.expiresAt - new Date().getTime() / 1000 - ONE_MINUTE_IN_SECONDS;

    if (timeout > 0) {
      this.tokenRenewalTimeout = setTimeout(() => {
        this.renewSession();
      }, timeout * MILLISECONDS_CONVERSION); // *1000 because setTimeout takes milliseconds
    }
  };
}
