export const humanReadableFileSize = (size?: number): string => {
  if (!size) {
    return '';
  }
  const i = Math.floor(Math.log(size) / Math.log(1024));
  const formattedFileSize = (size / 1024 ** i).toFixed(1);
  const formattedFileSizeString = `${formattedFileSize} ${
    ['B', 'kB', 'MB', 'GB', 'TB'][i]
  }`;
  return formattedFileSizeString;
};
