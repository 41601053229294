// Components
import { Card } from '@mui/material';
import { SidebarItem } from 'app/shared/components/SidebarItem';
import U21Icon from 'app/shared/components/U21Icon';

// Style
import styled from 'styled-components';

// Utils
import assets from 'assets/assets';
import FraudDAOLogoRed from 'app/shared/components/FraudDAOLogoRed';
import { IconFileSearch } from '@u21/tabler-icons';

export const Leftnav = () => {
  return (
    <RootStyle>
      <LogoBox>
        <FraudDAOLogoRed />
      </LogoBox>
      <MenuContent>
        <SidebarItem
          title="Search records"
          icon={<IconFileSearch />}
          href="/search-records"
          groupFirst
        />
        <SidebarItem
          title="File uploads"
          icon={<U21Icon assetIcon={assets.icons.uploads} />}
          href="/file-uploads"
        />
      </MenuContent>
    </RootStyle>
  );
};

const RootStyle = styled(Card)`
  grid-area: LeftNav;
  border-radius: 0;
  width: '180px';
  display: flex;
  flex-direction: column;
  /* hide this component in pdf exports */
  @media print {
    display: none;
  }
`;

const MenuContent = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  flex: 1;
`;

const LogoBox = styled.div`
  svg {
    width: 80;
    height: 40%;
  }
  height: 60px;
  display: flex;
  padding-top: 16px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  fill: theme.palette.grey[800];
`;
