import React from 'react';

// Components
import { Box } from '@mui/material';
import { AccountPopover } from 'app/shared/components/AccountPopover';

// Styles
import styled from 'styled-components';

const RootStyle = styled.div`
  height: 72px;
  background: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px solid #eee;
  grid-area: TopBar;
  /* hide this component in pdf exports */
  @media print {
    display: none;
  }
`;

export const TopBar = () => {
  return (
    <RootStyle>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          '& > *:not(:first-of-type)': {
            ml: { xs: 1.5, sm: 2, lg: 3 },
          },
        }}
      >
        <AccountPopover />
      </Box>
    </RootStyle>
  );
};
