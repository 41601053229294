import { Dispatch } from 'redux';
import { RootState } from 'app/store';

import {
  createAsyncThunk,
  AsyncThunkPayloadCreator,
  AsyncThunkOptions,
  AsyncThunk,
} from '@reduxjs/toolkit';

// copied over from redux toolkit since it's not exported
type AsyncThunkConfig = {
  state?: unknown;
  dispatch?: Dispatch;
  extra?: unknown;
  rejectValue?: unknown;
  serializedErrorType?: unknown;
  pendingMeta?: unknown;
  fulfilledMeta?: unknown;
  rejectedMeta?: unknown;
};

export function u21CreateAsyncThunk<
  ThunkArg = void,
  Returned = void,
  ThunkApiConfig extends AsyncThunkConfig = { state: RootState },
>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkApiConfig>,
  options?: AsyncThunkOptions<ThunkArg, ThunkApiConfig>,
): AsyncThunk<Returned, ThunkArg, ThunkApiConfig> {
  const u21PayloadCreator = async (arg, thunkAPI) => {
    try {
      // Pending: do some stuff before async call
      // Fulfilled: do some stuff after successful async call
      return await payloadCreator(arg, thunkAPI);
    } catch (err) {
      // Rejected: do some stuff after failed async call
      return thunkAPI.rejectWithValue(err);
    }
  };
  return createAsyncThunk<Returned, ThunkArg, ThunkApiConfig>(
    typePrefix,
    u21PayloadCreator,
    options,
  );
}
