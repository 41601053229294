import { useState } from 'react';
import { U21Button, U21Modal, U21TextField, U21Divider } from '@u21/components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled, { css } from 'styled-components';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { addApiKeyLabel, createApiKey } from 'features/settings/sliceSettings';
import { selectNewApiKey } from 'features/settings/selectors';
import { IconCopy } from '@u21/tabler-icons';

interface Props {
  disabled: boolean;
  children: string;
}

export const RequestNewApiKeyButton = (props: Props) => {
  const { children, disabled } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [textCopied, setTextCopied] = useState(false);
  const [label, setLabel] = useState('');
  const dispatch = useAppDispatch();
  const newApiKeyData = useAppSelector(selectNewApiKey);

  const createNewApiKey = () => {
    dispatch(createApiKey());
    setModalOpen(true);
  };

  const closeModal = () => {
    dispatch(
      addApiKeyLabel({
        id: newApiKeyData.newAPIKeyId,
        label,
      }),
    );
    setModalOpen(false);
  };

  const onCopy = () => {
    setTextCopied(true);
  };

  const onLabelChange = (text) => {
    setLabel(text);
  };

  return (
    <>
      <U21Button disabled={disabled} onClick={createNewApiKey}>
        {children}
      </U21Button>
      <U21Modal title="Create API Key" open={modalOpen} onClose={closeModal}>
        <ModalRow>
          This is the only time that the API key can be viewed. You cannot
          recover them after you dismiss this box. However, you can create new
          access keys at any time.
        </ModalRow>
        <ModalRow>
          <CopyContentcontainer>
            <ApiTextBox>{newApiKeyData?.newUnhashedApiKey}</ApiTextBox>
            <StyledCopyToClipboard
              text={newApiKeyData?.newUnhashedApiKey}
              onCopy={onCopy}
            >
              <IconCopy size={30} />
            </StyledCopyToClipboard>
            {textCopied && <TextCopied>Copied</TextCopied>}
          </CopyContentcontainer>
        </ModalRow>
        <StyledDivider horizontal />
        <ModalRow>
          <U21TextField
            placeholder="Label your API Key"
            value={label}
            onChange={onLabelChange}
          />
        </ModalRow>
      </U21Modal>
    </>
  );
};

const ModalRow = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const CopyContentcontainer = styled('div')`
  display: flex;
  align-items: center;
`;

const TextCopied = styled('div')`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    background-color: ${theme.palette.success.main};
    color: ${theme.palette.common.white};
    border-radius: 5px;
    padding: 0 5px;
  `}
`;

const StyledCopyToClipboard = styled(CopyToClipboard)`
  cursor: pointer;
  margin: 0 5px;
`;

const ApiTextBox = styled('div')`
  ${({ theme }) => css`
    background-color: ${theme.palette.grey[600]};
    color: ${theme.palette.common.white};
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border-radius: 5px;
  `}
`;

const StyledDivider = styled(U21Divider)`
  margin-bottom: 16px;
`;
