import { AuthClient } from 'app/shared/auth/models';

export class BaseAuthService implements AuthClient {
  provider = '';

  hasRefreshedToken = false;

  tokenPingInterval = -1;

  checkLastActivityInterval = -1;

  tokenRenewalTimeout;

  // eslint-disable-next-line class-methods-use-this
  public isAuthenticated = () => {};

  // eslint-disable-next-line class-methods-use-this
  public renewSession = () => {};

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, class-methods-use-this
  public login = async (authPayload = {}) => {};

  // eslint-disable-next-line class-methods-use-this
  public logout = async () => {};

  // eslint-disable-next-line class-methods-use-this
  public authorize = () => {};
}
