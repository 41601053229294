// Redux / Thunk Toolkits
import { PayloadAction } from '@reduxjs/toolkit';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';

import { retrieveEntities } from 'features/searchRecords/api';
import { Entity } from 'features/searchRecords/models';

const RECORD_NAME = 'recordSlice';

interface RecordSearchState {
  entities: Entity[];
  searchQuery?: String[];
  loadingRetrieveEntities: boolean;
}
export interface ListRecordPayload {
  matches: Entity[];
  number_of_matched_orgs: number;
}

export const initialState: RecordSearchState = {
  searchQuery: [],
  entities: [],
  loadingRetrieveEntities: false,
};

export interface RetrieveEntitiesPayload {
  searchQuery?: String[];
}

export const retrieveEntitiesThunk = u21CreateAsyncThunk(
  `${RECORD_NAME}/RETRIEVE_ENTITIES`,
  (payload: RetrieveEntitiesPayload) => {
    if (payload.searchQuery.length === 0)
      return {
        matches: [],
        number_of_matched_orgs: 0,
      };

    return retrieveEntities(payload);
  },
);

const recordSlice = u21CreateSlice({
  name: RECORD_NAME,
  initialState,
  reducers: {
    updateSearch: (draft, { payload }) => {
      draft.searchQuery = payload;
    },
    resetSearchResults: (draft) => {
      draft.searchQuery = [];
      draft.entities = [];
    },
  },
  extraReducers: (builder) => {
    builder.addLoadingCase(
      retrieveEntitiesThunk,
      'loadingRetrieveEntities',
      (draft, action: PayloadAction<ListRecordPayload>) => {
        draft.entities = action.payload.matches;
      },
    );
  },
});

export const recordSliceName = recordSlice.name;
export const { updateSearch, resetSearchResults } = recordSlice.actions;
export default recordSlice.reducer;
