import { ReactNode } from 'react';
import { GridLayout } from 'app/shared/components/GridLayout';

interface AppProps {
  readonly children: ReactNode;
}
const NO_AUTH_ROUTES = ['/email-login', '/login'];

export const StyledContainer = (props: AppProps) => {
  const { children } = props;

  if (NO_AUTH_ROUTES.includes(window.location.pathname)) {
    return <>children</>;
  }

  return <GridLayout>{children}</GridLayout>;
};
