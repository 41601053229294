import { U21TableColumnTypes } from '@u21/components';

const DATE_JOINED = {
  id: 'date_joined',
  Header: 'Date Joined',
  accessor: 'date_joined',
  type: U21TableColumnTypes.DATETIME,
};

const FIRST_SEEN = {
  id: 'first_seen',
  Header: 'First Seen',
  accessor: 'first_seen',
  type: U21TableColumnTypes.DATETIME,
};

const LAST_SEEN = {
  id: 'last_seen',
  Header: 'Last Seen',
  accessor: 'last_seen',
  type: U21TableColumnTypes.DATETIME,
};

const LAST_FLAGGED = {
  id: 'last_flagged_date',
  Header: 'Last Flagged',
  accessor: 'last_flagged_date',
  type: U21TableColumnTypes.DATETIME,
};

const LAST_BLOCKED = {
  id: 'last_blocked_date',
  Header: 'Last Blocked',
  accessor: 'last_blocked_date',
  type: U21TableColumnTypes.DATETIME,
};

const PLATFORM = {
  id: 'org_type',
  Header: 'Platform',
  accessor: 'org_type',
  type: U21TableColumnTypes.TEXT,
};

const TRANSACTION = {
  id: 'average_monthly_transacted_usd',
  Header: 'Transaction Avg/Mo.',
  accessor: 'average_monthly_transacted_usd',
  type: U21TableColumnTypes.TEXT,
};

const TIMES_BLOCKED = {
  id: 'number_of_times_blocked',
  Header: 'Times Blocked',
  accessor: 'number_of_times_blocked',
  type: U21TableColumnTypes.TEXT,
};
const TIMES_FLAGGED = {
  id: 'number_of_times_flagged',
  Header: 'Times Flagged',
  accessor: 'number_of_times_flagged',
  type: U21TableColumnTypes.TEXT,
};

const ACTIVE_TRANSACTIONS_MONTH = {
  id: 'number_of_active_transaction_months',
  Header: 'Active Trasactions/Mo.',
  accessor: 'number_of_active_transaction_months',
  type: U21TableColumnTypes.TEXT,
};

const TRANSACTION_DIRECTION = {
  id: 'transaction_direction',
  Header: 'Transaction Direction',
  accessor: 'transaction_direction',
  type: U21TableColumnTypes.TEXT,
};

const MEMO = {
  id: 'memo',
  Header: 'Memo',
  accessor: 'memo',
  type: U21TableColumnTypes.TEXT,
};

// ==== V2 ====
const BLOCKED = {
  id: 'blocked',
  Header: 'Blocked',
  accessor: 'blocked',
  type: U21TableColumnTypes.BOOLEAN,
};

const BLOCKED_DATE = {
  id: 'blocked_date',
  Header: 'Blocked Date',
  accessor: 'blocked_date',
  type: U21TableColumnTypes.DATETIME,
};

const BLOCKED_AMOUNT = {
  id: 'blocked_amount',
  Header: 'Blocked Amount',
  accessor: 'blocked_amount',
  type: U21TableColumnTypes.TEXT,
};

const BLOCKED_REASON = {
  id: 'blocked_reason',
  Header: 'Blocked Reason',
  accessor: 'blocked_reason',
  type: U21TableColumnTypes.TEXT,
};

const REGISTRATION_DATE = {
  id: 'registration_date',
  Header: 'Registration Date',
  accessor: 'registration_date',
  type: U21TableColumnTypes.DATETIME,
};

export const RECORD_TABLE_COLUMNS = [
  PLATFORM,
  DATE_JOINED,
  FIRST_SEEN,
  LAST_SEEN,
  TRANSACTION,
  ACTIVE_TRANSACTIONS_MONTH,
  TRANSACTION_DIRECTION,
  LAST_FLAGGED,
  TIMES_FLAGGED,
  LAST_BLOCKED,
  TIMES_BLOCKED,
  MEMO,
  // ==== V2 ====
  BLOCKED,
  BLOCKED_DATE,
  BLOCKED_AMOUNT,
  BLOCKED_REASON,
  REGISTRATION_DATE,
];
