import { Action } from 'redux';
import {
  AsyncThunk,
  createSlice,
  CreateSliceOptions,
  Slice,
  SliceCaseReducers,
  CaseReducer,
  ActionReducerMapBuilder,
} from '@reduxjs/toolkit';
import { NoInfer } from '@reduxjs/toolkit/dist/tsHelpers';

type Actions<T extends keyof any = string> = Record<T, Action>;

type CaseReducers<S, AS extends Actions> = {
  [T in keyof AS]: AS[T] extends Action ? CaseReducer<S, AS[T]> : void;
};

type BooleanKeys<T> = {
  [key in keyof T]: T[key] extends boolean ? key : never;
}[keyof T];

interface U21ActionReducerMapBuilder<State>
  extends ActionReducerMapBuilder<NoInfer<State>> {
  addLoadingCase: <Returned, ThunkArg>(
    thunk: AsyncThunk<Returned, ThunkArg, {}>,
    loadingKey: BooleanKeys<State>,
    reducer?: CaseReducer<State>,
  ) => U21ActionReducerMapBuilder<State>;
}

interface U21CreateSliceOptions<
  State,
  CR extends SliceCaseReducers<State> = SliceCaseReducers<State>,
  Name extends string = string,
> extends CreateSliceOptions<State, CR, Name> {
  extraReducers?:
    | CaseReducers<NoInfer<State>, any>
    | ((builder: U21ActionReducerMapBuilder<State>) => void);
}

export const u21CreateSlice = <
  State,
  CR extends SliceCaseReducers<State>,
  Name extends string = string,
>(
  options: U21CreateSliceOptions<State, CR, Name>,
): Slice<State, CR, Name> => {
  const { extraReducers } = options;
  if (typeof extraReducers !== 'function') {
    return createSlice(options);
  }
  return createSlice({
    ...options,
    extraReducers: (builder: ActionReducerMapBuilder<NoInfer<State>>) => {
      extraReducers({
        ...builder,
        addLoadingCase: (
          thunk,
          loadingKey: string | number | symbol, // retype to avoid ts errors when accessing draft
          reducer,
        ) => addLoadingCaseHelper(builder, thunk, loadingKey, reducer),
      });
    },
  });
};

const addLoadingCaseHelper = <State, Returned, ThunkArg>(
  builder: ActionReducerMapBuilder<State>,
  thunk: AsyncThunk<Returned, ThunkArg, {}>,
  loadingKey: string | number | symbol, // retype to avoid ts errors when accessing draft
  reducer?: CaseReducer<State>,
): U21ActionReducerMapBuilder<State> => {
  builder
    .addCase(thunk.pending, (draft) => {
      draft[loadingKey] = true;
    })
    .addCase(thunk.rejected, (draft) => {
      draft[loadingKey] = false;
    })
    .addCase(thunk.fulfilled, (draft, ...rest) => {
      draft[loadingKey] = false;
      reducer?.(draft, ...rest);
    });
  return {
    ...builder,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    addLoadingCase: (thunk, loadingKey, reducer) =>
      addLoadingCaseHelper(builder, thunk, loadingKey, reducer),
  };
};
