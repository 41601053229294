import {
  U21Card,
  U21Form,
  U21FormField,
  U21_FORM_FIELD_TYPES,
} from '@u21/components';
import styled from 'styled-components';
import { useAppDispatch } from 'app/hooks';

import { updateSearch } from 'features/searchRecords/sliceRecords';

export const RecordsSearchBar = () => {
  const dispatch = useAppDispatch();

  const mapQueryList = (value: string): String[] => {
    return value.split(',').map((input) => input.trim());
  };

  const validateString = (value: string): boolean => {
    if (value) {
      const arr = mapQueryList(value);
      const arrLength = arr.length;
      return arrLength >= 2 && arrLength <= 12;
    }
    return false;
  };
  return (
    <U21Card title="Search individual records">
      <StyledU21Form
        onSubmit={({ searchString }) => {
          const inputs = mapQueryList(searchString);
          dispatch(updateSearch(inputs));
        }}
      >
        <U21FormField
          fieldProps={{
            label: 'Search Entities by Hash IDs (Separating ids with a comma)',
          }}
          name="searchString"
          type={U21_FORM_FIELD_TYPES.TEXT}
          validate={(value) =>
            validateString(value)
              ? undefined
              : 'Invalid Amount of Hash IDs: please enter between 2-12 Hash IDs'
          }
        />
      </StyledU21Form>
    </U21Card>
  );
};

const StyledU21Form = styled(U21Form)`
  width: 60%;
`;
