import { current, PayloadAction } from '@reduxjs/toolkit';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';

import { APIKey } from 'features/settings/models';
import { Agent, AgentPayload } from 'features/agents/models';
import { agentPayloadToAgent } from 'features/agents/transformers';

import {
  getOrgApiKeys as getOrgApiKeysApi,
  deleteApiKey as deleteApiKeyApi,
  createApiKey as createApiKeyApi,
  addApiKeyLabel as addApiKeyLabelApi,
  getAllAgents as getAllAgentsApi,
  createAgent as createAgentApi,
} from 'features/settings/api';

import { toastError } from 'app/shared/toast';
import { apiKeyPayloadToAPIKey } from 'features/settings/transformers';

const SETTINGS_NAME = 'settingsSlice';

interface SettingsState {
  loadingApiKeys: boolean;
  loadingAgents: boolean;
  apiKeys: APIKey[];
  newUnhashedApiKey?: string;
  newAPIKeyId?: string;
  agents: Agent[];
}

export const initialState: SettingsState = {
  loadingApiKeys: false,
  loadingAgents: false,
  apiKeys: [],
  agents: [],
};

export const getOrgApiKeys = u21CreateAsyncThunk(
  `${SETTINGS_NAME}/GET_ORG_API_KEYS`,
  getOrgApiKeysApi,
);

export const deleteApiKey = u21CreateAsyncThunk(
  `${SETTINGS_NAME}/DELETE_API_KEY`,
  (id: string) => {
    return deleteApiKeyApi(id);
  },
);

export const createApiKey = u21CreateAsyncThunk(
  `${SETTINGS_NAME}/CREATE_API_KEY`,
  createApiKeyApi,
);

type AddApiKeyLabelAction = {
  id: string;
  label: string;
};
export const addApiKeyLabel = u21CreateAsyncThunk(
  `${SETTINGS_NAME}/ADD_API_LABEL_KEY`,
  ({ id, label }: AddApiKeyLabelAction) => {
    return addApiKeyLabelApi(id, label);
  },
);

export const getAllAgents = u21CreateAsyncThunk(
  `${SETTINGS_NAME}/GET_AGENTS`,
  getAllAgentsApi,
);

type CreateAgentAction = {
  fullName: string;
  email: string;
};
export const createAgent = u21CreateAsyncThunk(
  `${SETTINGS_NAME}/CREATE_AGENT`,
  async (payload: CreateAgentAction) => {
    try {
      const response = await createAgentApi(payload.fullName, payload.email);
      return response;
    } catch (e) {
      if (e.status === 409) {
        toastError('An agent with that email already exists');
      }
      throw e;
    }
  },
);

const settingsSlice = u21CreateSlice({
  name: SETTINGS_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addLoadingCase(getOrgApiKeys, 'loadingApiKeys', (draft, action) => {
        const apiKeyList: APIKey[] = action.payload.map((apiKey) =>
          apiKeyPayloadToAPIKey(apiKey),
        );
        draft.apiKeys = apiKeyList;
      })
      .addLoadingCase(deleteApiKey, 'loadingApiKeys', (draft, action) => {
        const apiKeys = [...current(draft.apiKeys)];
        const index = apiKeys.findIndex((a) => a.id === action.payload);
        apiKeys.splice(index, 1);
        draft.apiKeys = apiKeys;
      })
      .addLoadingCase(createApiKey, 'loadingApiKeys', (draft, action) => {
        draft.newAPIKeyId = action.payload.id;
        draft.newUnhashedApiKey = action.payload.unhashed_api_key;
      })
      .addLoadingCase(addApiKeyLabel, 'loadingApiKeys', (draft, action) => {
        const ApiKeyWithLabel: APIKey = apiKeyPayloadToAPIKey(action.payload);
        draft.apiKeys = [ApiKeyWithLabel, ...draft.apiKeys];
        draft.newAPIKeyId = null;
        draft.newUnhashedApiKey = null;
      })
      .addLoadingCase(
        getAllAgents,
        'loadingAgents',
        (draft, action: PayloadAction<AgentPayload[]>) => {
          draft.loadingAgents = false;
          const agents: Agent[] = action.payload.map((agent) =>
            agentPayloadToAgent(agent),
          );
          draft.agents = agents;
        },
      )
      .addLoadingCase(
        createAgent,
        'loadingAgents',
        (draft, action: PayloadAction<AgentPayload>) => {
          draft.agents = [agentPayloadToAgent(action.payload), ...draft.agents];
        },
      );
  },
});

export const settingsSliceName = settingsSlice.name;
export default settingsSlice.reducer;
