import {
  ListFileUploadPayload,
  setFileUploadError,
  setFileUploadProgress,
  setFileUploadSuccess,
  UploadFileProgress,
} from 'features/fileUploads/sliceFileUploads';
import { store } from 'app/store';
import { UploadFile } from 'features/fileUploads/models';
import { post } from 'app/shared/utils/fetchr';
import { getApiDomain } from 'app/shared/utils/getApiDomain';

export const uploadDataFile = async (accessToken: string, file: File) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const formData = new FormData();
  formData.append('file', file);

  const request = new XMLHttpRequest();
  const apiDomain = getApiDomain();
  request.open('POST', `${apiDomain}/v1/upload`, true);

  const onProgress = (e: ProgressEvent) => {
    if (e.lengthComputable) {
      const percent = (100 * e.loaded) / e.total;
      const data: UploadFileProgress = {
        percent,
        file_name: file.name,
        total: e.total,
      };
      store.dispatch(setFileUploadProgress(data));
    }
  };

  request.onload = () => {
    if (request.status !== 200) {
      const data = {
        file_name: file.name,
        error_message: 'Upload failed',
      };
      store.dispatch(setFileUploadError(data));
      return;
    }
    const data: UploadFile = JSON.parse(request.response);
    store.dispatch(setFileUploadSuccess(data));
  };

  request.onloadend = () => {
    request.upload.removeEventListener('progress', onProgress);
  };

  request.onerror = () => {
    request.upload.removeEventListener('progress', onProgress);
    const msg = 'Upload Failed';

    const data = {
      file_name: file.name,
      error_message: msg,
    };
    store.dispatch(setFileUploadError(data));
  };

  request.onabort = () => {
    request.upload.removeEventListener('progress', onProgress);
    const msg = 'Upload Aborted';
    const data = {
      file_name: file.name,
      error_message: msg,
    };
    store.dispatch(setFileUploadError(data));
  };

  request.upload.addEventListener('progress', onProgress);

  Object.keys(headers).forEach((i) => {
    request.setRequestHeader(i, headers[i]);
  });
  request.send(formData);
};

export const retrieveDataFiles = async (
  payload,
): Promise<ListFileUploadPayload> => {
  return post('/uploads/list', payload);
};
